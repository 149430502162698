<template>
    <div class="student-information-container">
        <div class="student-container">
            <v-card outlined class="card-flex">
                <div class="logo">
                    <v-img contain :src="logoPath"></v-img>
                </div>
                <div class="info-student">
                    <p class="subject">
                        {{ truncateSubject }}
                        <span>{{ $t('modules.exams.assestment.studentInformation.subject') }}</span>
                    </p>
                    <p class="fullname">
                        <span>{{ $t('modules.exams.assestment.studentInformation.studentLabel') }}</span>
                        {{ student.fullName }}
                    </p>
                </div>
            </v-card>
        </div>
        <div class="info-container">
            <v-card outlined class="references">
                <div>
                    <h4>{{ $t('modules.exams.assestment.studentInformation.references') }}</h4>
                    <ul>
                        <li>
                            <svg height="20" width="20"><circle cx="10" cy="10" r="6" class="answered-fill"/></svg>
                            {{ $t('modules.exams.assestment.studentInformation.answered') }}
                        </li>
                        <li>
                            <svg height="20" width="20"><circle cx="10" cy="10" r="6" class="not-answered-fill" /></svg>
                            {{ $t('modules.exams.assestment.studentInformation.notAnswered') }}
                        </li>
                        <li>
                            <svg height="20" width="20"><circle cx="10" cy="10" r="6" class="to-review-fill" /></svg>
                            {{ $t('modules.exams.assestment.studentInformation.toReview') }}
                        </li>
                        <li>
                            <svg height="20" width="20"><circle cx="10" cy="10" r="6" class="actual-fill" /></svg>
                            {{ $t('modules.exams.assestment.studentInformation.actual') }}
                        </li>
                    </ul>
                </div>
            </v-card>
            <v-card outlined class="alert">
                <p>
                    <v-icon color="warning">mdi-alert-outline</v-icon>
                    {{ $t('modules.exams.assestment.studentInformation.logo.warning') }}
                </p>
            </v-card>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        props: {
            subject: {type: String, required: true},
            student: {
                type: Object,
                required: true,
                validator (value) {
                    const keys = Object.keys(value);
                    return keys.some((item) => item === 'fullName')
                }
            },
        },
        computed: {
            truncateSubject() {
                return this.subject && this.subject.length > 100 ? `${this.subject.slice(0, 100)  }...` : this.subject
            },
            ...mapGetters({
                logoPath: 'commons/getLogoImageUrl',
            }),
        }
    }
</script>

<style lang="sass" scoped>
    @import '../Sass/variables_exams'

    .student-information-container
        display: flex
        flex-direction: column
        min-width: 100%
        margin: 0

        .student-container
            display: flex
            flex-direction: column
            width: 100%
            margin-bottom: .3rem

            .card-flex
                display: flex
                flex-direction: row
                width: 100%
                border: $card-border
                padding: .2rem 0

                .logo
                    display: flex
                    width: 100px
                    height: 30px
                    width: 10%

                .info-student
                    display: flex
                    flex-direction: row
                    align-items: center
                    justify-content: center
                    width: 90%

                    p
                        display: flex
                        flex-direction: row
                        align-items: center
                        font-size: 14px
                        margin-bottom: 0
                        margin-right: 1rem
                        position: relative

                        span
                            font-weight: bold
                            margin-right: .5rem

                    .subject
                        flex-direction: row-reverse
                        text-align: right
                        width: 56%

                    .fullname
                        text-align: left
                        width: 44%

        .info-container
            display: flex
            width: 100%

            .references
                width: 60%
                border: $card-border
                display: flex
                flex-direction: row
                align-items: center
                margin-right: .3rem

                div
                    display: flex
                    flex-direction: row
                    align-content: center
                    padding: .3rem 1rem
                    width: 100%

                    h4
                        display: inline
                        font-size: 14px
                        font-weight: bold

                    ul
                        list-style: none
                        list-style-type: none
                        display: inline-block

                        li
                            display: flex
                            flex-direction: row
                            align-content: center
                            float: left
                            font-size: 12px
                            margin-right: 1rem

                            .circle-question
                                display: inline-block
                                width: 5px
                                height: auto
                                border-radius: 15px
                                margin-right: .5rem

        .alert
            width: 40%
            border: $card-border
            border-radius: 4px
            padding: .3rem

            p
                display: flex
                align-items: center
                margin: 0
                font-size: 14px

                i
                    margin-right: 1rem
</style>
